// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-source-wagtail-preview-template-js": () => import("./../../../node_modules/gatsby-source-wagtail/preview-template.js" /* webpackChunkName: "component---node-modules-gatsby-source-wagtail-preview-template-js" */),
  "component---src-components-templates-acknowledgements-js": () => import("./../../../src/components/templates/Acknowledgements.js" /* webpackChunkName: "component---src-components-templates-acknowledgements-js" */),
  "component---src-components-templates-article-js": () => import("./../../../src/components/templates/Article.js" /* webpackChunkName: "component---src-components-templates-article-js" */),
  "component---src-components-templates-home-page-js": () => import("./../../../src/components/templates/HomePage.js" /* webpackChunkName: "component---src-components-templates-home-page-js" */),
  "component---src-components-templates-posts-home-page-js": () => import("./../../../src/components/templates/PostsHomePage.js" /* webpackChunkName: "component---src-components-templates-posts-home-page-js" */),
  "component---src-components-templates-video-js": () => import("./../../../src/components/templates/Video.js" /* webpackChunkName: "component---src-components-templates-video-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

